import { createAction, props } from "@ngrx/store";

export const login = createAction('[Auth] login',
    props<{payload: any}>()
)

export const loginSuccess = createAction('[Auth] login Success',
    props<{accessToken: string, refreshToken: string, userId: number }>()
)

export const getUser = createAction('[Auth] get User',
    props<{payload: any}>()
)

export const getUserSuccess = createAction('[Auth] get User Success',
    props<{userDetails: any }>()
)

export const getPatientAlert = createAction('[Auth] get Patient Alert',
    props<{payload: any}>()
)

export const getPatientAlertSuccess = createAction('[Auth] get Patient Alert Success',
    props<{patientAlertData: any }>()
)
export const getControl = createAction('[Auth] get Control',
    props<{payload: any}>()
)

export const getControlSuccess = createAction('[Auth] get Control Success',
    props<{controls: any }>()
)
export const getUserRole = createAction('[Auth] get UserRole',
    props<{payload: any}>()
)

export const getUserRoleSuccess = createAction('[Auth] get UserRole Success',
    props<{userRoleData: any }>()
)

export const setAccessToken = createAction('[Auth] set Access Token',
    props<{payload: string}>()
)

export const setAccessTokenSuccess = createAction('[Auth] set Access Token Success',
    props<{accessToken: string }>()
)

export const loginFailure = createAction('[Auth] login Failure',
    props<{error: any}>()
)

export const logout = createAction('[Auth] logout',
    props<{payload: any}>()
)

export const logoutSuccess = createAction('[Auth] logout Success')

